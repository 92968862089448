// src/features/formDataSlice.js
import { createSlice } from '@reduxjs/toolkit';


export interface formData {
    recipientName: string;
    fullAddress: string;
    phoneNumber: string;
    itemDescription: string;
    itemWeight: string;
    additionalDetails: string;
    state: string;
    latlng: string;
  }
  
  const initialState: formData[] = [{
    recipientName: "",
    fullAddress: "",
    phoneNumber: "",
    itemDescription: "",
    itemWeight: "",
    additionalDetails: "",
    state: "",
    latlng: "",
  }];


export const formDataSlice = createSlice({
  name: 'formData',
  initialState: {
    formData: initialState
  },
  reducers: {
    addFormData: (state, action) => {
      // Add a new object to the array
      state.formData.push(action.payload);
    },
    updateFormData: (state, action) => {
      // Update an existing object in the array based on index
      const { index, newData } = action.payload;
      if (state.formData[index]) {
        state.formData[index] = { ...state.formData[index], ...newData };
      }
    },
    removeFormData: (state, action) => {
      // Remove an object from the array by index
      state.formData.splice(action.payload, 1);
    },
    resetFormData: (state) => {
      // Optionally reset the formData array to an empty state
      state.formData = initialState;
    },
  },
});

export const { addFormData, updateFormData, removeFormData, resetFormData } = formDataSlice.actions;
export default formDataSlice.reducer;
