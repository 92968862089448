import axios from "axios";
import { ClientJS } from 'clientjs';

const baseUrl = process.env.REACT_APP_PUBLIC_BASE_URL as string;
export default function api(url = baseUrl) {
  const client = new ClientJS();
  const fingerprint = client.getFingerprint();
  const browser = client.getBrowser();
  const OS =  client.getOS();
  const instance = axios.create({
    // Set the baseURL of your API
    baseURL: url || baseUrl,
    headers: {
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.request.use(
    (config) => {
      const configFromLocalStorage: any = localStorage.getItem("token");
      const refreshToken: any = localStorage.getItem('refreshToken')
      const url: any = config.url

      // Add the config from local storage to the request headers
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${configFromLocalStorage}`,
        ...(['api/user/sessions'].includes(url) && {refreshToken})
      };
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      // Do something with the response data
      return response;
    },
    async (error) => {
      // Handle response errors
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        // Get the refresh token from local storage or wherever it's stored
        const refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken) {
          try {
            // Request a new access token using the refresh token
            const config = {
              headers: {
                "Content-Type": "application/json",
                deviceId: `${fingerprint}`,
                deviceName: `${browser}/${OS}`,
                refreshToken,
              },
            };
            const response = await instance.post("api/auth/refresh-token", {}, config);
            const newAccessToken = response.headers.token;
            const newRefreshToken = response.headers.refreshtoken;

            // Update the access token in local storage
            localStorage.setItem("token", newAccessToken);
            localStorage.setItem("refreshToken", newRefreshToken);

            // Retry the original request with the new access token
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            return axios(originalRequest);
          } catch (refreshError) {
            // Handle refresh token request error
            return Promise.reject(refreshError);
          }
        }
      }

      if (error.response.status === 403) {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        window.location.href = "/login";
      }
      // If there is no refresh token or the refresh token request fails, reject the request
      return Promise.reject(error);
    }
  );

  const logoutUser = async () => {
    try {
      await axios.post("/api/auth/logout");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      window.location.href = "/login"; // Redirect to login page
    } catch (logoutError) {
      window.location.href = "/login"; // Redirect to login page
    }
  };

  const getUserSessions = async () => {
    const response = await instance.get(`api/user/sessions`);
    return response.data.data.data.documents;
  };

  const deleteSession = async (id:string) => {
    const response = await instance.put(`api/user/sessions/${id}`);
    return response.data.data.data;
  };
  

  const getVehicles = async () => {
    const response = await instance.get(`api/vehicles`);
    return response.data.data.data;
  };

  const getEstimate = async (input: any) => {
    const response = await instance.post("api/order/estimate", input);
    return response;
  };

  const placeOrder = async (input: any) => {
    const response = await instance.post(baseUrl + "api/order", input);
    return response?.data?.data?.data;
  };

  const getOrderDetails = async (input: any) => {
    const response = await instance.get(`api/order/${input}`);
    return response;
  };

  const getLatestOrder = async (env: string) => {
    const response = await instance.get(`api/order?env=${env}&limit=1&skip=0`);
    return response?.data?.data?.data?.documents;
  };

  const getOrderHistoryDelivered = async (env: string) => {
    const response = await instance.get(
      `api/order?env=${env}&orderStatus=delivered`
    );
    return response?.data?.data?.data?.documents;
  };

  const getSubPlans = async () => {
    const response = await instance.get(`api/subscription/getplans`);
    return response?.data?.data?.data;
  };

  const getUserProfile = async () => {
    const response = await instance.get(`api/user/profile`);
    return response?.data?.data?.data;
  };

  const getApiKeys = async () => {
    const response = await instance.get(`api/user/generate-keys`);
    return response?.data?.data;
  };

  const getAllOrders = async (env: string) => {
    const response = await instance.get(`api/order?env=${env}`);
    return response?.data?.data?.data?.documents;
  };

  const getSingleOrder = async (orderId: string, env: string) => {
    const response = await instance.get(`api/order/${orderId}?env=${env}`);
    return response?.data?.data?.data;
  };

  const initiateSubscription = async (data: any) => {
    const response = await instance.post(
      `api/payment/paystack/initializepayment`,
      data
    );
    return response?.data?.data?.data?.reference;
  };
  const changePassword = async (data: any) => {
    const response = await instance.post(` api/auth/change-password`, data);
    return response;
  };
  const getTransactions = async () => {
    const response = await instance.get(`api/payment/transactions`);
    return response?.data?.data?.data?.documents;
  };
  const getWalletbalance = async () => {
    const response = await instance.get(`api/user/wallet/get-balance`);
    return response?.data?.data;
  };

  const getInternationalEstimate = async (data:any) => {
    const response = await instance.post(`api/order/international/rate`, data);
    return response?.data?.data?.data;
  };
 
  const initiateInternationalOrder = async (data:any) => {
    const response = await instance.post(`api/order/international/initiate`, data);
    return response?.data?.data?.data;
  };

  const getInterstatEstimate = async (data:any) => {
    const response = await instance.post(`api/order/interstate/rate`, data);
    return response?.data?.data?.data;
  };
 
  const initiateInterstateOrder = async (data:any) => {
    const response = await instance.post(`api/order/interstate/initiate`, data);
    return response?.data?.data?.data;
  };

  const submitFeedback = async (data:any) => {
    const response = await instance.post(`api/order/feedback`, data);
    return response?.data?.data?.data;
  };

 

  return {
    getVehicles,
    placeOrder,
    getOrderDetails,
    getEstimate,
    getLatestOrder,
    getOrderHistoryDelivered,
    getAllOrders,
    getSubPlans,
    getUserProfile,
    initiateSubscription,
    getApiKeys,
    changePassword,
    getWalletbalance,
    getTransactions,
    getSingleOrder,
    getUserSessions,
    deleteSession,
    getInternationalEstimate,
    initiateInternationalOrder,
    initiateInterstateOrder,
    getInterstatEstimate,
    submitFeedback
  };
}
