import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../services/api";
import SvgIcon from "../shared/SvgIcon";
import BasicModal from "./Modal";
import Spinner from "./Spinner";

const CustomerFeedback = ({ orderId }: { orderId: string }) => {
  const apiRequest = api();
  const queryClient = useQueryClient();
  const env = useSelector((state: RootState) => state.env.env);
  const [openModal, setOpenModal] = useState(true);
  const [state, setState] = useState({
    deliveryServiceRating: true,
    deliveryStaffRating: true,
    riderRating: true,
    feedbackRating: 0,
    feedbackSuggestion: "",
  });

  const submitFeedback = useMutation((data: any) =>
    apiRequest.submitFeedback(data)
  );

  const handleSubmit = async () => {
    try {
      await submitFeedback.mutateAsync({ ...state, orderId });
      queryClient.invalidateQueries(["SINGLE_ORDER", orderId, env]);
      setOpenModal(false);
      toast.success("Thank you for your Feedback");
    } catch (err) {
      toast.error("An Error occured submitting feedback");
    }
  };

  const handleDisable = () => {
    if (state.feedbackRating === 0) return true;
    return false;
  };

  const ModalContent = () => {
    const numbers: number[] = Array(10)
      .fill(0)
      .map((_, i) => i + 1);
    return (
      <Wrapper>
        <p style={{ display: "flex", justifyContent: "flex-end" }}>
          <span className="cancel-icon" onClick={() => setOpenModal(false)}>
            <SvgIcon name="cancel" width="20" height="20" />
          </span>
        </p>
        <h1>How satisfied were you with our service?</h1>
        <p>
          Your feedback is super helpful to us in order to keep improving our
          services for your satisfaction.
        </p>
        <div className="numbers">
          {numbers.map((number) => {
            return (
              <span
                onClick={() =>
                  setState((prev) => ({ ...prev, feedbackRating: number }))
                }
                style={{
                  background:
                    state.feedbackRating === number ? "#F66D00" : "#ffffff",
                  color: state.feedbackRating === number ? "#ffffff" : "black",
                }}
                key={number}
              >
                {number}
              </span>
            );
          })}
        </div>
        <h2>Would you tell us more about your experience?</h2>
        <div className="thumbs-feedback">
          <p>Delivery Service</p>
          <p>
            <span
              onClick={() =>
                setState((prev) => ({ ...prev, deliveryServiceRating: true }))
              }
            >
              <SvgIcon
                name="thumbsup"
                color={state.deliveryServiceRating ? "#F66D00" : "black"}
              />
            </span>
            <span
              onClick={() =>
                setState((prev) => ({ ...prev, deliveryServiceRating: false }))
              }
            >
              <SvgIcon
                name="thumbsdown"
                color={!state.deliveryServiceRating ? "#F66D00" : "black"}
              />
            </span>
          </p>
        </div>
        <div className="thumbs-feedback">
          <p>Delivery Staff</p>
          <p>
            <span
              onClick={() =>
                setState((prev) => ({ ...prev, deliveryStaffRating: true }))
              }
            >
              <SvgIcon
                name="thumbsup"
                color={state.deliveryStaffRating ? "#F66D00" : "black"}
              />
            </span>
            <span
              onClick={() =>
                setState((prev) => ({ ...prev, deliveryStaffRating: false }))
              }
            >
              <SvgIcon
                name="thumbsdown"
                color={!state.deliveryStaffRating ? "#F66D00" : "black"}
              />
            </span>
          </p>
        </div>
        <div className="thumbs-feedback">
          <p>Pickup/ Delivery riderRating</p>
          <p>
            <span
              onClick={() =>
                setState((prev) => ({ ...prev, riderRating: true }))
              }
            >
              <SvgIcon
                name="thumbsup"
                color={state.riderRating ? "#F66D00" : "black"}
              />
            </span>
            <span
              onClick={() =>
                setState((prev) => ({ ...prev, riderRating: false }))
              }
            >
              <SvgIcon
                name="thumbsdown"
                color={!state.riderRating ? "#F66D00" : "black"}
              />
            </span>
          </p>
        </div>
        <div className="improvements">
          <label>What improvements would you suggest? (Optional)</label>
          <textarea
            value={state.feedbackSuggestion}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                feedbackSuggestion: e.target.value,
              }))
            }
            placeholder="Enter your feedback here"
          />
        </div>
        <button onClick={handleSubmit} disabled={handleDisable()}>
          {submitFeedback.isLoading ? <Spinner /> : 'Submit Feedback'}
        </button>
      </Wrapper>
    );
  };

  return (
    <BasicModal
      open={openModal}
      close={() => setOpenModal(false)}
      height={700}
      width={600}
      content={ModalContent()}
    />
  );
};

export default CustomerFeedback;

const Wrapper = styled.div`
  padding: 50px;
  color: #323643;

  > h1 {
    font-size: 20px;
    text-align: center;
  }
  > h2 {
    font-size: 16px;
    font-weight: 600;
    margin: 15px 0;
  }
  > p {
    text-align: center;
  }

  .numbers {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    margin-top: 20px;

    span {
      border: 1px solid black;
      padding: 4px 12px;
      border-radius: 10px;
      cursor: pointer;
    }
  }

  .thumbs-feedback {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
  }

  .thumbs-feedback p {
    display: flex;
    gap: 10px;
    cursor: pointer;
  }
  .improvements {
    label {
      font-weight: 600;
    }

    textarea {
      min-height: 100px;
      width: 100%;
      padding: 4px 10px;
      margin-top: 10px;
    }
  }

  .cancel-icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: #74748014;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  button {
    background: #f66d00;
    color: #ffffff;
    border: none;
    width: 100%;
    padding: 20px;
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
  }

  button:disabled {
    opacity: 50%;
  }
`;
