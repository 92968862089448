import { configureStore } from "@reduxjs/toolkit";
// import authReducer from "./auth/auth"
import UserSlice from "./auth/auth";
import LocationSlice from "./location/location";
import DeliveryLocationSlice from "./location/delivery";
import OrderSlice from "./orderInfo/orderInfo";
import EnvironmentSlice from "./environment/env";
import formDataSlice from "./placeorderform/placeorderFormData";

export const store = configureStore({
  reducer: {
    user: UserSlice,
    location: LocationSlice,
    deliverylocation: DeliveryLocationSlice,
    order: OrderSlice,
    env: EnvironmentSlice,
    formData: formDataSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
