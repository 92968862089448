import { Routes, Route } from "react-router-dom";
import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import ProtectedRoute from './components/ProtectedRoute';
import Landingpage from './pages/Landingpage';
import Login from './pages/Login';
import Placeorder from './pages/PlaceOrder';
import "./App.css";
import Profile from "./pages/Profile";
import Rider from "./pages/Rider"
import Subscription from "./pages/Subscription";
import Fundwallet from "./pages/FundWallet";
import Wallet from "./pages/Wallet";
import Order from "./pages/Order";
import OrderHistoryPage from "./pages/OrderHistory";
import SingleOrder from "./pages/SingleOrder";
import About from "./components/about/About";
import TrackOrderPage from "./pages/TrackOrderPage";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotpasswordOtp from "./pages/ForgotPasswordOtp";
import NewPassword from "./pages/NewPassword";
import VerifyEmail from "./pages/VerifyEmail";
import Home from "./pages/Home";
import Terms from "./pages/Terms";
import Interstate from "./pages/Interstatepage";
import International from "./pages/Internationalpage";
import { Pricing } from "./pages/Pricing";
import { TrackingNoAuthPage } from "./pages/TrackingNoAuthPage";
import { useEffect } from "react";
import Blog from "./pages/Blog";
import { Privacy } from "./components/Privacy";
import SingleBlogPost from "./pages/Singleblogpost";
import { RiderTerms } from "./components/RiderTerms";
import Delete from "./pages/Delete";
import Checkout from "./pages/Checkout";
import usePageVisibility from "./hooks/usePageVisibility";
require("typeface-poppins");
require("typeface-red-hat-display");

function App() {
  const GAKeys =  process.env.REACT_APP_GOOGLE_ANALYTICS as string;
  const FPKeys = process.env.REACT_APP_FACEBOOK_ANALYTICS as string
  ReactGA.initialize(GAKeys)
  ReactPixel.init(FPKeys)


  usePageVisibility()
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

  }, []);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Landingpage />} />
        <Route path="*" element={<Landingpage />} />
        <Route path="/login" element={<Login/>}/>
        <Route path="/blog" element={<Blog/>}/>
        <Route path="/blog/:blogid" element={<SingleBlogPost/>}/>
        <Route path="/signup" element={<Signup/>}/>
        <Route path="/verify" element={<VerifyEmail/>}/>
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/forgotpasswordotp" element={<ForgotpasswordOtp />} />
        <Route path="/newpassword" element={<NewPassword />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/policy" element={<Privacy />} />
        <Route path="/riderterms" element={<RiderTerms />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/track" element={<TrackingNoAuthPage />} />
        <Route path="/delete-account" element={<Delete/>}/>
        <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path = "/placeorder" element={<ProtectedRoute><Placeorder/></ProtectedRoute>}/>
        <Route path = "/interstate" element={<ProtectedRoute><Interstate/></ProtectedRoute>}/>
        <Route path = "/international" element={<ProtectedRoute><International/></ProtectedRoute>}/>
        <Route path = "/profile" element={<ProtectedRoute><Profile/></ProtectedRoute>}/>
        <Route path = "/subscription" element={<ProtectedRoute><Subscription/></ProtectedRoute>}/>
        <Route path = "/fundwallet" element={<ProtectedRoute><Fundwallet/></ProtectedRoute>}/>
        <Route path = "/transactions" element={<ProtectedRoute><Wallet/></ProtectedRoute>}/>
        <Route path = "/history" element={<ProtectedRoute><OrderHistoryPage/></ProtectedRoute>}/>
        <Route path = "/order/" element={<ProtectedRoute><TrackOrderPage/></ProtectedRoute>}/>
        <Route path = "/order/:orderId" element={<ProtectedRoute><Order/></ProtectedRoute>}/>
        <Route path = "/history/:orderId" element={<ProtectedRoute><SingleOrder/></ProtectedRoute>}/>
        <Route path = "/rider/:riderID" element={<ProtectedRoute><Rider/></ProtectedRoute>}/>
      </Routes>
    </div>
  );
}

export default App;
