import React, { useState } from "react";
import {useQuery} from "react-query"
import ControlledAccordions from "./Accordion";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/store";
import api from "../services/api";
import BasicModal from "./Modal";
import { deleteDeliveryLocation } from "../redux/location/delivery";
import MapScreen from "./MapScreen";
import { toast } from "react-toastify";
import DeliveryMap from "./DeliveryMap";
import colours from "../styles/appColours";
import locationService from "../redux/location/locationService";
import { LineWave } from "react-loader-spinner";
import MapModal from "./MapModal";
import Spinner from "./Spinner";
import utils from "../utils/utils";
import Feedback from "./Feedback";
import { addFormData, updateFormData, removeFormData } from "../redux/placeorderform/placeorderFormData";

interface VehicleTypes {
  eBike: string;
  smallVan: string;
  largeVan: string;
}

export interface Vehicles {
  _id: string;
  name: VehicleTypes;
  active: boolean;
  base_fee?: string;
  cost_per_km?: string;
  cost_per_min?: string;
}

const del = {
  eBike: "/ebike.png",
  smallVan: "/evan.png",
  largeVan: "/evan.png",
};

const PlaceOrderScreen = () => {
  const apiRequest = api();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pickupAddy: any = localStorage.getItem("pickup-address");
  const storedPickup: any = JSON.parse(pickupAddy);
  const [open, setOpen] = useState(false);
  const [modalstate, setModalState] = useState("");
  const [openOrder, setOpenOrder] = useState(false);
  const [openPickupModal, setOpenPickupModal] = useState(false);
  const [estimatedCost, setEstimatedCost] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [pickupDetails, setPickupDetails] = useState({
    fullAddress: storedPickup?.fullAddress || "",
    senderName: storedPickup?.senderName || "",
    phoneNumber: storedPickup?.phoneNumber || "",
    deliveryVehicleId: "6260db3625437d5a6d023ded",
    state: storedPickup?.state || "",
    latlng: storedPickup?.latlng || "",
  });
  const [formValues, setFormValues] = useState([
    {
      recipientName: "",
      fullAddress: "",
      phoneNumber: "",
      itemDescription: "",
      itemWeight: "",
      additionalDetails: "",
      state: "",
      latlng: "",
    },
  ]);

  const address = useSelector(
    (state: RootState) => state.location.pickupAddress
  );
  const deliveryDetails = useSelector(
    (state: RootState) => state.deliverylocation.deliveryAddress
  );

  const formData = useSelector((state: RootState) => state.formData.formData); 

  // this is commented out till when we have different types of vehicles for the customer to choose from
  // const { data: vehicles } = useQuery("GET_VEHICLES",  () => apiRequest.getVehicles());

  const { data: user } = useQuery("USER_PROFILE",  () => apiRequest.getUserProfile());


  const handleInputChange = (index: number, field: string, value: string) => {
    dispatch(updateFormData({ index, newData: { [field]: value } }));
  };

  let handlePickupChange = (e: any) => {
    let newFormValues: any = { ...pickupDetails };
    newFormValues[e.target.name] = e.target.value;
    setPickupDetails(newFormValues);
  };

  let addFormFields = () => {
    const lastElement = formData[formData.length - 1];
    const lastElement2 = deliveryDetails[deliveryDetails.length - 1];
    if (
      lastElement?.itemDescription === "" ||
      !lastElement2?.fullAddress ||
      lastElement?.phoneNumber === "" ||
      lastElement?.itemWeight === ""
    ) {
      toast("Form incomplete");
    } else {
      dispatch(addFormData({ recipientName: "",
      fullAddress: "",
      phoneNumber: "",
      itemDescription: "",
      itemWeight: "",
      additionalDetails: "",
      state: "",
      latlng: "", }));
    }
  };
  let removeFormFields = (i: any) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const pickupInfo = locationService.deliveryInfo([pickupDetails], [address]);
  localStorage.setItem("pickup-address", JSON.stringify(pickupInfo[0]));

  let handleSubmit = async (event: any) => {
    if(!user?.subscription?.valid) {
      setModalState('subscribe')
      setOpenOrder(true)
      return
    }
    event.preventDefault();
    setOpenOrder(true);
    setModalState("loading");
    const deliveryInfo = locationService.deliveryInfo(
      formValues,
      deliveryDetails
    );

    const payload = {
      pickUpDetails: pickupInfo,
      deliveryDetails: deliveryInfo,
    };

    if (!user?.subscription) {
      const storepayload = {
        pickUpDetails: pickupInfo,
        deliveryDetails: deliveryInfo,
        estimatedCost: estimatedCost?.estimatedCost,
        channel: "web",
        email: user?.email,
      };
      setModalState("subscribe");
      setOpenOrder(true);
      localStorage.setItem("NON-SUB-DELIVERY", JSON.stringify(storepayload));
      return;
    }

    // try {
    //   const costEstimate: any = await apiRequest.getEstimate(payload);
    //   setEstimatedCost(costEstimate?.data);
    //   setModalState("estimate");
    // } catch (err: any) {
    //   setModalState("default");
    //   Bugsnag.notify(err);
    // }
  };

  async function PlaceOrder() {
    setLoading(true);

    const deliveryInfo = locationService.deliveryInfo(
      formData,
      deliveryDetails
    );
    const payload = {
      pickUpDetails: pickupInfo,
      deliveryDetails: deliveryInfo,
      estimatedCost: estimatedCost?.estimatedCost,
      channel: "web",
      email: user?.email,
    };
    localStorage.setItem("NON-SUB-DELIVERY", JSON.stringify(payload));
    navigate('/checkout')
    // await apiRequest
    //   .placeOrder(payload)
    //   .then((response) => {
    //     if (response?.successfulOrderCount > 0) {
    //       setLoading(false);
    //       setOpenOrder(true);
    //       setModalState("order-placed");
    //     }
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     setOpenOrder(false);
    //     setFeedbackModal((prev) => {
    //       return {
    //         ...prev,
    //         open: true,
    //         type: "error",
    //         message:
    //           err?.response?.data?.data?.failedOrders[0]?.error ||
    //           err?.response?.data?.message ||
    //           err?.response?.data?.error ||
    //           "Location is outside our jurisdiction for pickup/delivery",
    //       };
    //     });
    //     Bugsnag.notify(err);
    //   });
  }

  function handleDisable() {
    const deliveryInfo = locationService.deliveryInfo(
      formData,
      deliveryDetails
    );
    if (!pickupInfo[0].fullAddress) return true;
    if (!pickupDetails.senderName) return true;
    if (!pickupDetails.phoneNumber) return true;
    if (pickupDetails.phoneNumber.length < 10) return true;
    if ( pickupInfo[0]?.state !== 'Lagos') return true;
    const checker = deliveryInfo.some(
      (details: {
        fullAddress: any;
        recipientName: any;
        phoneNumber: any;
        itemWeight: any;
        state: any;
        latlng: any;
        itemDescription: any;
      }) => {
        if (
          !details.fullAddress ||
          !details.recipientName ||
          !details.phoneNumber ||
          details.phoneNumber.length !== 10 ||
          !details.itemWeight ||
          details.itemWeight > 15 ||
          !details.state ||
           details.state !== 'Lagos' ||
          !details.latlng ||
          !details.itemDescription
        ) {
          // const requiredFields = document.querySelectorAll(".required");
          // requiredFields.forEach((input: any) => {
          //   if (input?.value.trim() === "") {
          //     input.classList.add("error");
          //   }
          // });
          return true;
        } else {
          return false;
        }
      }
    );
    return checker;
  }

  const ModalContent = (state: string) => {
    switch (state) {
      case "loading":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LineWave
              height="100"
              width="100"
              color={colours.secondary}
              ariaLabel="line-wave"
            />
          </div>
        );
      case "estimate":
        return (
          <EstimateWrap>
            <p>
              Your subscription covers {estimatedCost?.subscription?.noOfOrders}{" "}
              more deliveries.{" "}
            </p>
            <CashButton onClick={PlaceOrder}>
              {loading ? <Spinner /> : "Find a Rider"}
            </CashButton>
          </EstimateWrap>
        );
      case "order-placed":
        return (
          <OrderPlaced>
            <div>
              <h1>Order Successfully placed!</h1>
            </div>
            <div>
              <p className="order-pick">Your order will be picked up soon.</p>
            </div>
            <CashButton onClick={() => navigate("/home")}>
              Return to Home
            </CashButton>
          </OrderPlaced>
        );
      case "subscribe":
        return (
          <CTASub>
            <p>
              Kindly subscribe to schedule a delivery, click the{" "}
              <strong>Subscribe</strong> button below to view the features of
              our subscription plans.
            </p>

            <CashButton
              style={{ marginBottom: "0px" }}
              onClick={() => navigate("/subscription")}
            >
              Subscribe
            </CashButton>
            <CashButton
              style={{
                background: "#ffffff",
                color: "#000000",
                border: "1px solid #000000",
              }}
              onClick={() => navigate("/home")}
            >
              Return to Home
            </CashButton>
          </CTASub>
        );
      default:
        return (
          <EstimateWrap2>
            <h4>Sorry for the inconvenience</h4>
            <p style={{ width: "100%", borderBottom: "1px solid black" }}></p>
            <p>There is no RIder available now</p>
            <Link to={"/subscription"}>
              <p className="kindly-note ">Become a subscriber</p>
            </Link>
            <CashButton onClick={() => setOpenOrder(false)}>OK</CashButton>
          </EstimateWrap2>
        );
    }
  };

  return (
    <Wrapper>
      <Form>
        <Title>Send a Package</Title>
        <ControlledAccordions
          title={"Pickup Details"}
          deliveryinfo={
            <DeliveryWrapper>
              <Left>
                <InputDesign>
                  <InputLabel>Name</InputLabel>
                  <Name
                    type="text"
                    onChange={(e) => handlePickupChange(e)}
                    name="senderName"
                    id="senderName"
                    value={pickupDetails.senderName}
                    onBlur={(evt) => {
                      if (evt.target.value === "") {
                        const name = document.getElementById("senderName");
                        name?.classList.add("error");
                      }
                    }}
                  />
                </InputDesign>
                <InputDesign onClick={() => setOpenPickupModal(true)}>
                  <InputLabel>Pickup Address</InputLabel>
                  <MapModal
                    width={"100vw"}
                    height={"100vh"}
                    icon={
                      <PickupAddress
                        type="textarea"
                        name="fullAddress"
                        id="pickupaddress"
                        value={
                          pickupInfo[0]?.fullAddress ||
                          pickupDetails.fullAddress
                        }
                        onChange={(e) => handlePickupChange(e)}
                        onBlur={(evt) => {
                          if (evt.target.value === "" || pickupInfo[0]?.state !== 'Lagos') {
                            const name =
                              document.getElementById("pickupaddress");
                            name?.classList.add("error");
                          } else {
                            const name =
                              document.getElementById("pickupaddress");
                            name?.classList.remove("error");
                          }
                        }}
                      />
                    }
                    content={<MapScreen closeFunction={() => setOpen(false)} />}
                  />
                 {pickupInfo[0]?.state !== 'Lagos' && <span style={{textAlign: 'left', fontSize: '12px', color: 'red'}}>This address is not within Lagos</span>}
                </InputDesign>
              </Left>
              <Right>
                
                {/* <InputDesign>
                  this is commented out till when we have different types of vehicles for the customer to choose from
                  <PickupVehicle>Select Pickup Vehicle</PickupVehicle>
                  <InputDiv>
                    {vehicles?.length > 0 ? (
                      vehicles.map((vehicle:Vehicles, index: number) => (
                        <DeliveryVehicle
                          active={
                            pickupDetails?.deliveryVehicleId === vehicle?._id
                          }
                          onClick={() => {
                            setActive(!active);
                            vehicle.active
                              ? setPickupDetails((prevState) => {
                                  return {
                                    ...prevState,
                                    deliveryVehicleId: vehicle?._id,
                                  };
                                })
                              : toast("Vehicle not available right now");
                          }}
                          key={index}
                          style={{ opacity: vehicle.active ? 1 : 0.3 }}
                        >
                          <img
                            src={
                              del[vehicle.name as unknown as keyof typeof del]
                            }
                            width={80}
                            height={60}
                            alt={vehicle.name as unknown as keyof typeof del}
                          />
                        </DeliveryVehicle>
                      ))
                    ) : (
                      <DeliveryVehicle active={active}>
                        loading....
                      </DeliveryVehicle>
                    )}
                  </InputDiv>
                </InputDesign> */}
                <InputDesign
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    alignItems: "flex-end",
                  }}
                >
                  <div style={{ width: "15%", minWidth: "80px" }}>
                    <Smalldropdown
                      onChange={(e) => handlePickupChange(e)}
                      name="code"
                      disabled
                    >
                      <option value={"+234"}>+234</option>
                    </Smalldropdown>
                  </div>
                  <div style={{ width: "85%" }}>
                    <Name
                      value={utils.AllowOnlyNumbers(pickupDetails?.phoneNumber)}
                      id="pickupphoneNumber"
                      onChange={(e) => handlePickupChange(e)}
                      onBlur={(evt) => {
                        if (
                          evt.target.value === "" ||
                          evt.target.value.length !== 10
                        ) {
                          const name =
                            document.getElementById("pickupphoneNumber");
                          name?.classList.add("error");
                        } else {
                          const name =
                            document.getElementById("pickupphoneNumber");
                          name?.classList.remove("error");
                        }
                      }}
                      placeholder="Phone Number"
                      name="phoneNumber"
                      type={"text"}
                      maxLength={10}
                    />
                  </div>
                </InputDesign>
              </Right>
            </DeliveryWrapper>
          }
        />
        {formData.map((element, index) => (
          <ControlledAccordions
            key={index}
            deliveryinfo={
              <DeliveryWrapper>
                <Left>
                <InputDesign>
                    <InputLabel>Name</InputLabel>
                    <Name
                      type="text"
                      name="recipientName"
                      id={"recipientName"}
                      placeholder="Receiver's Fullname"
                      className="required"
                      value={utils.formatText(element.recipientName) || ""}
                      onChange={(e) => {
                        // handleChange(index, e);
                        handleInputChange(index, 'recipientName', e.target.value)
                        const name = document.getElementById("recipientName");
                        name?.classList.remove("error");
                      }}
                      onBlur={(evt) => {
                        if (evt.target.value === "") {
                          const name = document.getElementById("recipientName");
                          name?.classList.add("error");
                        }
                      }}
                    />
                  </InputDesign>
                  <InputDesign onClick={() => setOpen(true)}>
                    <InputLabel>Delivery address</InputLabel>
                    <div style={{ width: "100%" }}>
                      <MapModal
                        width={"100vw"}
                        height={"100vh"}
                        icon={
                          <DeliveryDetails
                            style={{ color: "black" }}
                            value={deliveryDetails[index]?.fullAddress || ""}
                            autoComplete="off"
                            name="fullAddress"
                            id="fullAddress"
                            className="required"
                            placeholder="Delivery address"
                            onChange={(evt: any) => {
                              handleInputChange(index, 'fullAddress', evt.target.value)
                              // handleChange(index, evt);
                            }}
                            onBlur={(evt) => {
                              if (evt.target.value === "" || deliveryDetails[index]?.state !== 'Lagos') {
                                const name =
                                  document.getElementById("fullAddress");
                                name?.classList.add("error");
                              } else {
                                const name =
                                  document.getElementById("fullAddress");
                                name?.classList.remove("error");
                              }
                            }}
                          />
                        }
                        content={
                          <DeliveryMap
                            id={index}
                            closeMap={() => {
                              setOpen(false);
                            }}
                          />
                        }
                      />
                    </div>
                    {deliveryDetails[index]?.state !== 'Lagos' && <span style={{textAlign: 'left', fontSize: '12px', color: 'red'}}>This address is not within Lagos</span>}
                  </InputDesign>
                  <InputDesign>
                    <InputLabel>Weight</InputLabel>
                    <Weight
                      name="itemWeight"
                      className="required"
                      id="itemWeight"
                      placeholder="Enter Weight"
                      maxLength={3}
                      value={utils.formatWeight(element.itemWeight) || ""}
                      onChange={(e) => {
                        // handleChange(index, e);
                        handleInputChange(index, 'itemWeight', e.target.value)
                        const name = document.getElementById("itemWeight");
                        name?.classList.remove("error");
                      }}
                      onBlur={(evt) => {
                        if (
                          evt.target.value === "" ||
                          Number(evt.target.value) > 15
                        ) {
                          const name = document.getElementById("itemWeight");
                          name?.classList.add("error");
                        }
                      }}
                    />
                  </InputDesign>
                  <Text>Item must not exceed 15kg</Text>
                </Left>
                <Right>
                  <InputDesign>
                    <InputDesign
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                        alignItems: "flex-end",
                      }}
                    >
                      <div style={{ width: "15%", minWidth: "80px" }}>
                        <Smalldropdown
                          onChange={(e) => handlePickupChange(e)}
                          name="code"
                          disabled
                        >
                          <option value={"+234"}>+234</option>
                        </Smalldropdown>
                      </div>
                      <div style={{ width: "85%" }}>
                        <PhoneNumber
                          type="tel"
                          name="phoneNumber"
                          id="phoneNumber"
                          placeholder="8031234567"
                          className="required"
                          maxLength={10}
                          pattern=""
                          value={utils.formatWeight(element.phoneNumber) || ""}
                          onChange={(e) => {
                            // handleChange(index, e);
                            handleInputChange(index, 'phoneNumber', e.target.value)
                            const name = document.getElementById("phoneNumber");
                            name?.classList.remove("error");
                          }}
                          onBlur={(evt) => {
                            if (
                              evt.target.value === "" ||
                              evt.target.value.length !== 10
                            ) {
                              const name =
                                document.getElementById("phoneNumber");
                              name?.classList.add("error");
                            }
                          }}
                        />
                      </div>
                    </InputDesign>
                  </InputDesign>
                  <InputDesign>
                    <InputLabel>Item description</InputLabel>
                    <Description
                      type="text"
                      className="required"
                      name="itemDescription"
                      id="itemDescription"
                      value={element.itemDescription || ""}
                      onChange={(e) => {
                        // handleChange(index, e);
                        handleInputChange(index, 'itemDescription', e.target.value)
                        const name = document.getElementById("itemDescription");
                        name?.classList.remove("error");
                      }}
                      onBlur={(evt) => {
                        if (evt.target.value === "") {
                          const name =
                            document.getElementById("itemDescription");
                          name?.classList.add("error");
                        }
                      }}
                    />
                  </InputDesign>
                  <InputDesign>
                    <InputLabel>Extra details about the delivery</InputLabel>
                    <ExtraDetails
                      name="additionalDetails"
                      value={element.additionalDetails || ""}
                      onChange={(e) => {
                        handleInputChange(index, 'additionalDetails', e.target.value)
                        // handleChange(index, e)
                      }}
                    />
                  </InputDesign>
                </Right>
              </DeliveryWrapper>
            }
            deletes={
              index ? (
                <span
                  onClick={() => {
                    dispatch(deleteDeliveryLocation(index));
                    dispatch(removeFormData(index))
                  }}
                >
                  x
                </span>
              ) : null
            }
            title={`Delivery ${index + 1}`}
          ></ControlledAccordions>
        ))}
        <div
          className="button-section"
          style={{ display: "flex", gap: "20px" }}
        >
          <AddDelivery onClick={addFormFields}>Add Delivery</AddDelivery>
          {user?.subscription?.valid ? (
            <SubmitButton
              onClick={PlaceOrder}
              disabled={handleDisable()}
              type="submit"
            >
              {!loading ? "Schedule Delivery" : <Spinner />}
            </SubmitButton>
          ) : (
            <SubmitButton
              onClick={handleSubmit}
              disabled={handleDisable()}
              type="submit"
            >
              Estimate cost
            </SubmitButton>
          )}
        </div>
      </Form>
      <BasicModal
        width={500}
        height={300}
        open={openOrder}
        close={() => setOpenOrder(false)}
        content={ModalContent(modalstate)}
      />
      <Feedback
        message={feedbackModal?.message}
        feedback={feedbackModal?.type}
        open={feedbackModal?.open}
        close={() =>
          setFeedbackModal((prev) => {
            return { ...prev, open: false };
          })
        }
      />
    </Wrapper>
  );
};

export default PlaceOrderScreen;

const EstimateWrap = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  margin: 0;
  gap: 20px;
`;
const CTASub = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 50px;
  margin: 0;
  gap: 15px;
  text-align: center;
`;
const OrderPlaced = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;
  margin: 0;
  gap: 15px;

  h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #333333;
  }

  .estimate {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #333333;
    > span {
      font-weight: 500;
    }
  }
  .order-pick {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    /* Gray 1 */

    color: #333333;
  }
  .kindly-note {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    color: #eb5757;
    width: 100%;
  }
`;

const EstimateWrap2 = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px 0;
  h4 {
    font-weight: 500;
    font-size: 20px;
  }
  p {
    margin: 10px 0;
  }
  .kindly-note {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #eb5757;
    width: 100%;
  }
`;

const CashButton = styled.button`
  margin: 0 auto;
  text-align: center;
  display: block;
  /* width: 450px; */
  width: 60%;
  height: 45px;
  margin-bottom: 40px;
  background-color: ${colours.black};
  color: ${colours.white};
  border-radius: 6px;
  border: none;
  font-size: 18px;
  font-weight: 500;
  @media (max-width: 425px) {
    width: 90%;
  }
  &:disabled {
    background-color: red;
  }
`;

const DeliveryDetails = styled.input`
  padding-left: 10px;
  width: 100%;
  display: block;
  height: 45px;
  border: 1px solid #828282;
  outline: none;
  border-radius: 5px;

  &.error {
    border: 2px solid rgb(255, 86, 97);
  }
`;

const Title = styled.h3`
  text-align: left;
`;
const Name = styled.input`
  padding-left: 10px;
  display: block;
  height: 45px;
  border: 1px solid #828282;
  outline: none;
  border-radius: 5px;
  background-color: #ffffff;
  width: 100%;
  &.error {
    border: 2px solid rgb(255, 86, 97);
  }
`;
const PickupAddress = styled.input`
  padding-left: 10px;
  display: block;
  height: 40px;
  border: 1px solid #828282;
  outline: none;
  border-radius: 5px;
  background-color: #fdfcfc;
  width: 100%;
  &.error {
    border: 2px solid rgb(255, 86, 97);
  }
`;

const PhoneNumber = styled.input`
  padding-left: 10px;
  display: block;
  height: 40px;
  border: 1px solid #828282;
  outline: none;
  border-radius: 5px;
  width: 100%;
  &.error {
    border: 2px solid rgb(255, 86, 97);
  }
`;
const Weight = styled.input`
  padding-left: 10px;
  display: block;
  height: 40px;
  border: 1px solid #828282;
  outline: none;
  border-radius: 5px;
  width: 100%;
  &.error {
    border: 2px solid rgb(255, 86, 97);
  }
`;
const Description = styled.input`
  padding-left: 10px;
  display: block;
  height: 40px;
  border: 1px solid #828282;
  outline: none;
  border-radius: 5px;
  width: 100%;
  &.error {
    border: 2px solid rgb(255, 86, 97);
  }
`;
const ExtraDetails = styled.textarea`
  padding-left: 10px;
  padding-top: 10px;
  display: block;
  height: 80px;
  border: 1px solid #828282;
  outline: none;
  border-radius: 5px;
  width: 100%;
  resize: vertical;
  &.error {
    border: 2px solid rgb(255, 86, 97);
  }
`;
const Text = styled.p`
  font-size: 14px;
  font-family: cursive;
  color: ${colours.primary};
  margin: 0px;
  text-align: left;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  @media (max-width: 1024px) {
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
  }
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  @media (max-width: 1024px) {
    width: 100%;
    gap: 20px;
  }
`;
const DeliveryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */

  margin-top: 20px;
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
const Wrapper = styled.main`
  background-color: ${colours.background};
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1024px) {
    background-color: ${colours.white};
    padding-top: 30px;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 60%;
  max-width: 1040px;
  padding: 30px;
  margin: 0 auto;
  background-color: ${colours.white};
  margin-top: 80px;
  margin-bottom: 70px;
  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    width: 85%;
    padding: 0px;
    margin-top: 30px;
  }
`;
const Smalldropdown = styled.select`
  display: block;
  height: 40px;
  border: 1px solid #828282;
  outline: none;
  background-color: #ffffff;
  width: 100%;
  border-radius: 5px;
  color: #000000;
`;
const AddDelivery = styled.button`
  margin: 0 auto;
  text-align: center;
  display: block;
  width: 45%;
  height: 45px;
  font-weight: 700;
  border: 1px solid #f66d00;
  background-color: #ffffff;
  color: #f66d00;
  border-radius: 5px;
  margin-bottom: 20px;
  @media (max-width: 425px) {
    width: 90%;
  }
`;

const SubmitButton = styled.button`
  margin: 0 auto;
  text-align: center;
  display: block;
  width: 45%;
  height: 45px;
  margin-bottom: 40px;
  background-color: #f66d00;
  color: ${colours.white};
  border-radius: 5px;
  border: none;
  font-weight: 700;
  @media (max-width: 425px) {
    width: 90%;
  }
  &:disabled {
    background-color: #f66d00;
    opacity: 0.6;
  }
`;

const Price = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  color: ${colours.primary};
  text-align: center;
`;

const InputDesign = styled.div`
  position: relative;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    width: 100%;
    border: 1px solid ${colours.secondary};
  }
`;

const InputLabel = styled.div`
  position: absolute;
  top: -5px;
  left: 30px;
  font-size: 12px;
  font-family: "Times New Roman", Times, serif;
  background-color: #ffffff;
  color: #8c8888;
  @media (max-width: 425px) {
    background-color: #ffffff;
  }
`;

const InputDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  padding: 20px 0px;
  overflow: auto;
  width: 100%;
  @media (max-width: 600px) {
    max-width: 90vw;
    justify-content: space-between;
    padding: 20px;
  }
`;

const PickupVehicle = styled.p`
  font-family: Red Hat Display;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.17px;
  text-align: left;
  color: #333333;
`;

const DeliveryVehicle = styled.div<{ active: boolean }>`
  background-color: ${colours.background};
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  border-radius: 12px;
  cursor: pointer;
  border: 2px solid
    ${(props) =>
      props.active ? `${colours.primary}` : `${colours.background}`};
  @media (max-width: 600px) {
    min-width: 100px;
  }
`;
