import { useState, Key } from "react";
import styled from "styled-components";
import Paystack from "./Paystack";
import colours from "../styles/appColours";
import BasicModal from "./Modal";
import Loader from "./Loader";
import utils from "../utils/utils";
import {useQuery, useMutation} from "react-query"
import { toast } from "react-toastify";
import Spinner from "./Spinner";
import Subinfo from "./Subinfo";
import api from "../services/api";

const SubscriptionScreen = () => {
  const apiRequest =  api()
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(true)
  const [reference, setReference]= useState("");
  const [modalState, setModalState] = useState("");
  const [subPlan, setSubPlan] = useState<any>({
    name: "",
    amount: "",
  });
  const { data: subscriptionPlans, isLoading } = useQuery("SUBSCRIPTION_PLANS",  () => apiRequest.getSubPlans());
  const { data: userProfile } = useQuery("USER_PROFILE",  () => apiRequest.getUserProfile());
  const initiateSubscriptionPayment = useMutation((data) => apiRequest.initiateSubscription(data));
  
  const Subscribe = async (req: any) => {
    try{
      setModalState('loading')
      const response: any = await initiateSubscriptionPayment.mutateAsync(req);
      setReference(response)
      setModalState('payment')
    }catch(err){
      toast.error("An Error occured Initializing payment")
    }
  };

  const getVatAmount = (amount: any) => {
    const vatamount = amount * 0.075;
    const result = amount + vatamount;
    return result;
  };


  const ModalContent = (state: string) => {
    switch (state) {
      case "loading":
        return <Spinner componentlevel={true} />;
      default:
        return (
          <ModalWrap>
            <h4 style={{ fontWeight: 500 }}>
              Subscribe to {subPlan?.name} plan
            </h4>
            <p>
              Amount :{" "}
              <strong>
                NGN{(subPlan?.amount / 100).toLocaleString("en-US",{
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </strong>
            </p>
            <p>
              VAT(7.5%) :{" "}
              <strong>
                NGN{((subPlan?.amount * 0.075) / 100).toLocaleString("en-US",{
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </strong>
            </p>
            <p>
              Total :{" "}
              <strong>
                NGN{getVatAmount(subPlan?.amount / 100).toLocaleString("en-US",{
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </strong>
            </p>
            <p>Click to Proceed</p>
            <div
              style={{ width: "100%", textAlign: "center" }}
              onClick={() => setOpenModal(false)}
            >
              <Paystack
                amount={getVatAmount(subPlan?.amount)}
                email={userProfile?.email}
                reference={reference}
                paymentType={`SUBSCRIPTION_${subPlan?.name}`}
                userId={userProfile?._id}
              />
            </div>
          </ModalWrap>
        );
    }
  };
  if (isLoading) return <Loader />;
  return (
    <Wrapper>
      <CardsWrapper>
        {subscriptionPlans?.map((subs: any, index: Key | null | undefined) => (
          <Cards key={index}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <SubType>{subs?.name}</SubType>
              <SubAmount>
                {`N${Number(subs?.amount / 100).toLocaleString()}`}
              </SubAmount>
            </div>
            <SubInfo>
              <SubListItem>
                N{(subs?.baseFee / 100).toLocaleString()} per delivery within
                Lagos.
              </SubListItem>
              <SubListItem>
                {subs?.allowedDeliveries} pick ups and deliveries
              </SubListItem>
              <SubListItem>{subs?.description || "N/A"}</SubListItem>
              {subs?.benefits?.length > 0 &&
                utils
                  .formatDescription(subs?.benefits)
                  .map((benefit) => (
                    <SubListItem key={benefit}>{benefit}</SubListItem>
                  ))}
              <SubListItem>{subs?.validity} day validity period</SubListItem>
            </SubInfo>
            {userProfile?.subscription?.name === subs?.name && userProfile?.subscription?.valid && (
              <Active><span style={{fontWeight: 600}}>Status:</span>  Active</Active>
            )}
            {userProfile?.subscription?.name === subs?.name && userProfile?.subscription?.valid ? (
              <RolloverButton 
               onClick={() => {
                setSubPlan((prev: any) => {
                  return { ...prev, name: subs?.name, amount: subs?.amount };
                });
                setOpenModal(true);
                Subscribe({
                  amount: subs?.amount,
                  paymentType: `SUBSCRIPTION_${subs?.name}`,
                });
              }}>Rollover Subscription</RolloverButton>
            ) : (
              <SubButton
                onClick={() => {
                  setSubPlan((prev: any) => {
                    return { ...prev, name: subs?.name, amount: subs?.amount };
                  });
                  setOpenModal(true);
                  Subscribe({
                    amount: subs?.amount,
                    paymentType: `SUBSCRIPTION_${subs?.name}`,
                  });
                }}
              >
                Subscribe Now
              </SubButton>
            )}
            <BasicModal
              open={openModal}
              close={() => setOpenModal(false)}
              height={320}
              width={350}
              content={ModalContent(modalState)}
            />

          {/* <BasicModal close={()=>setOpen(false) } open={open} content={<Subinfo toggle={()=>setOpen(false)}/>} width={'90vw'} height={'90vh'}/> */}
          </Cards>
        ))}
      </CardsWrapper>
    </Wrapper>
  );
};

export default SubscriptionScreen;

const Wrapper = styled.main`
  min-height: 100vh;
  height: 100%;
  background-color: ${colours.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* box-sizing: border-box; */
  /* border: 1px solid red; */
  @media (max-width: 425px) {
    margin-bottom: 40px;
  }
  @media (max-width: 375px) {
  }
`;

const Title = styled.p`
  color: #4d4949;
  font-size: 38px;
  margin: 10px 0px 25px 0px;
  @media (max-width: 425px) {
    display: none;
  }
`;

const Active = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* padding: 14px 16px; */
  gap: 10px;
  height: 30px;
  /* width: 290px; */
  border-radius: 5px;
  color: #6fcf97;
`;

const CardsWrapper = styled.section`
  background-color: ${colours.background};
  /* height: 85vh; */
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 5%;
  column-gap: 5%;
  border-radius: 32px;
  margin: 0px;
  margin-top: 100px;
  padding: 0px;
  /* border: 1px solid green; */
  box-sizing: content-box;
  @media (max-width: 425px) {
    width: 90vw;
    gap: 20px;
  }
  @media (max-width: 375px) {
  }
`;
const ModalWrap = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 10px 0;
  > p {
    text-align: left;
  }
`;
const Cards = styled.section`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 40%;
  min-width: 350px;
  margin-bottom: 20px;
  /* height: 100%; */
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0px 38.5522px 66.0896px ${colours.background};
  border: none;
  @media (max-width: 425px) {
    width: 90%;
    :last-child {
      margin-bottom: 40px;
    }
    :first-child {
      margin-top: 20px;
    }
  }
  @media (max-width: 375px) {
  }
`;

const SubType = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  /* Gray 1 */

  color: #333333;
`;

const SubAmount = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;

  /* Gray 1 */

  color: #f66d00;
`;

const SubInfo = styled.ul`
  color: #8c8888;
  font-size: 15px;
  font-style: normal;
  margin: 0px 0px 15px 0px;
  padding: 0px;
  line-height: 35px;
  text-align: left;
  list-style: none;
  margin-top: 20px;
`;

const SubListItem = styled.li`
  margin: 0;
`;

const SubButton = styled.p`
  cursor: pointer;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #f66d00;
  text-align: left;
`;

const RolloverButton = styled.p`
  cursor: pointer;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  background-color: #f66d00;
  text-align: left;
  color: #ffffff;
  padding: 5px;
  border-radius: 4px;
  width: 170px;
`;
