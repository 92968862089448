import {  useState } from "react";
import axios from "axios";
import styled from "styled-components";
import {useQuery, useMutation} from "react-query"
import { toast } from "react-toastify";
import {  useParams } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import colours from "../styles/appColours";
import Loader from "../components/Loader";
import BasicModal from "../components/Modal";
import Paystack from "../components/Paystack";
import ResponsiveDrawer from "../components/Sidebar";
import Ordersummary from "../components/Ordersummary";
import utils from "../utils/utils";
import api from "../services/api";

import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import CustomerFeedback from "../components/CustomerFeedback";

function SingleOrderScreen() {
  const apiRequest =  api()
  const params  = useParams();
  const orderId = params.orderId as string;
  const [openModal, setOpenModal] = useState(false);
  const [modalState, setModalState] = useState("");
  const [message, setMessage] = useState("");
  const [reference, setReference] = useState("");
  const [amountToKobo, setAmountToKobo] = useState(0);
  const env = useSelector(
    (state: RootState) => state.env.env
  );
   
  const { data: order, isLoading: loading } = useQuery(["SINGLE_ORDER",orderId, env],  () => apiRequest.getSingleOrder(orderId, env), {
    onError: () => toast.error("Error fetching order")
  });
  const { data: UserInfo } = useQuery("USER_PROFILE",  () => apiRequest.getUserProfile());
  const initiatePayment = useMutation((data) => apiRequest.initiateSubscription(data));


  const InitializePayment = async (req: any) => {
    try{
      setModalState('loading')
      const response: any = await initiatePayment.mutateAsync(req);
      setReference(response)
      setModalState("paystack");
    }catch(err){
      toast.error("An Error occured Initializing payment")
    }
  };
  // const CashPayment = (req: any) => {
  //   axios
  //     .post(baseUrl + "api/payment/cash", req, config)
  //     .then((res) => {
  //       // setReference(res?.data?.initialize?.data?.reference);
  //       console.log(res);
  //     })
  //     .catch((err) => console.log(err));
  // };
  const ModalContent = (state: string) => {
    switch (state) {
      case "payment":
        return (
          <PaymentWrap>
            <PaymentText>Select a Payment Method</PaymentText>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <div>
                <input
                  type="radio"
                  name="parcelType"
                  id="document"
                  value="document"
                />
                <label style={{ marginLeft: "20px" }} htmlFor="documents">
                  Pay With Wallet
                </label>
              </div>
              <div>
                <input
                  onChange={() => {
                    InitializePayment({
                      amount: order?.estimatedCost,
                      paymentType: utils.paymenttype(order?.deliveryType),
                      orderId: orderId,
                    });
                    setAmountToKobo(order?.estimatedCost);
                  }}
                  type="radio"
                  name="parcelType"
                  id="packages"
                  value="packages"
                />
                <label style={{ marginLeft: "20px" }} htmlFor="packages">
                  Pay With Card
                </label>
              </div>
            </div>
          </PaymentWrap>
        );
      case "paystack":
        return (
          <PaymentWrap2>
            <p>Click to Proceed</p>
            <Paystack
              amount={amountToKobo}
              email={UserInfo?.email}
              reference={reference}
            />
          </PaymentWrap2>
        );
      case "message":
        return <>{message}</>;
      default:
        return <></>;
    }
  };
  if (loading) return <Loader />;
  return (
    <Wrapper>
      <Back onClick={() => window.history.back()}>
        <BsArrowLeft fontSize={"24px"} />
      </Back>
      <Page>
        <Timestamp>
          <Location>
            <Addy>
              <Icon>
                <img
                  src={"/location.png"}
                  height={"100%"}
                  width={"100%"}
                  alt="location marker"
                />
              </Icon>
              <Place>
                <Text>Order Placed</Text>
                <Time>{`${utils.getDate(order?.createdAt)}, ${utils.getTime(
                  order?.createdAt
                )}`}</Time>
              </Place>
            </Addy>
            {order?.order_status_history.map((timeStamp: any, key: any) => (
              <Addy key={key}>
                <Icon>
                  <img
                    src={"/location.png"}
                    height={"100%"}
                    width={"100%"}
                    alt="location marker"
                  />
                </Icon>
                <Place>
                  <Text>{utils.switchOrderStatus(timeStamp.status)}</Text>
                  <Time>{`${utils.getDate(timeStamp?.time)}, ${utils.getTime(
                    timeStamp?.time
                  )}`}</Time>
                </Place>
              </Addy>
            ))}
            {
              (order?.deliveryMethod === "hub-and-spoke" && order?.deliveryType === "domestic") ? null : !order?.payment?.transactionId ?  <p>Awaiting payment</p>  : null
            }
          </Location>
          <p>
            N{utils.formatAmount(order?.estimatedCost / 100)}
          </p>
        </Timestamp>

        <Content>
          <Ordersummary
            onSubmit={() => {
              setModalState("payment");
              setOpenModal(true);
            }}
            pickupDetails={{
              senderName: order?.pickUpDetails[0]?.senderName,
              fullAddress: order?.pickUpDetails[0]?.fullAddress,
              state: order?.pickUpDetails[0]?.state || "Not Available",
              code: "",
              phoneNumber: order?.pickUpDetails[0]?.phoneNumber,
            }}
            deliveryDetails={{
              recepientName:
                order?.deliveryDetails[0]?.recipientName || "Not Available",
              fullAddress:
                order?.deliveryDetails[0]?.fullAddress || "Not Available",
              state:
                order?.deliveryDetails[0]?.countyName ||
                order?.deliveryDetails[0]?.state ||
                "Not Available",
              code: "",
              postalCode:
                order?.deliveryDetails[0]?.postalCode,
              phoneNumber:
                order?.deliveryDetails[0]?.phoneNumber || "Not Available",
              cityName: order?.deliveryDetails[0]?.cityName,
              countryCode:
                order?.deliveryDetails[0]?.countryCode,
              email: order?.deliveryDetails[0]?.email,
            }}
            shipmentDetails={{
              weight: order?.packages[0]?.weight || "Not Available",
              height: order?.packages[0]?.dimensions?.height || "Not Available",
              width: order?.packages[0]?.dimensions?.width || "Not Available",
              length: order?.packages[0]?.dimensions?.length || "Not Available",
              monetaryAmount: order?.monetaryAmount,
            }}
            buttonText={"Make Payment"}
            deliveryType={order?.deliveryType}
            shouldButtonShow={(!(order?.deliveryMethod === "hub-and-spoke" && order?.deliveryType === "domestic") ? false : order?.payment?.transactionId) || order?.orderStatus === 'verified'}
            // shouldButtonShow={!order?.payment?.transactionId || order?.orderStatus === 'verified' || !(order?.deliveryMethod === "hub-and-spoke" && order?.deliveryType === "domestic")  }
          />
        </Content>
      </Page>
      <BasicModal
        open={openModal}
        close={() => setOpenModal(false)}
        height={250}
        width={500}
        content={ModalContent(modalState)}
      />
      {order.orderStatus === 'delivered' && !order?.completedFeedback && <CustomerFeedback orderId={orderId}  />}
    </Wrapper>
  );
}
const SingleOrder = () => {
  const params  = useParams();
  const orderId = params.orderId as string;
  return (
    <ResponsiveDrawer title={`Order ID: ${orderId}`} content={<SingleOrderScreen />} />
  );
};
export default SingleOrder;

const Wrapper = styled.main`
  background-color: #f5f6f6;
  min-height: 100vh;
  height: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 600px) {
    background-color: ${colours.background};
  }
`;

const Content = styled.section`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 60%;
  max-width: 700px;
  padding: 30px;
  margin: 0 auto;
  background-color: ${colours.white};
  border-radius: 10px;
  margin-top: 80px;
  margin-bottom: 70px;
  @media (max-width: 1024px) {
    margin-top: 20px;
    width: 100%;
  }
`;

const Page = styled.div`
  display: flex;

  @media (max-width: 1024px) {
   flex-direction: column;
  }
`
const Timestamp = styled.section`
  display: flex;
  gap: 10px;
  width: 40%;
  max-width: 400px;
  padding: 30px;
  margin: 0 auto;
  background-color: ${colours.white};
  border-radius: 10px;
  margin-top: 80px;
  margin-bottom: 70px;

  > p {
    /* Body bold */

    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: left;

    /* eFTD- Orange */

    color: #fe7f1f;
  }
  @media (max-width: 1024px) {
    margin-top: 20px;
    width: 100%;
  }
`;

const PaymentWrap = styled.div`
  background-color: ${colours.background};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 20px;
  gap: 5%;
`;
const PaymentWrap2 = styled.div`
  background-color: ${colours.background};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  gap: 5%;
`;

const PaymentText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */

  color: #000000;
`;

const Back = styled.div`
  margin: 25px;
  width: 35px;
  height: 35px;
  background-color: ${colours.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;


const Location = styled.div`
  /* height: 20vh; */
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  > p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    height: 39px;
    color: #eb5757;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    background: #ffe8e8;
    border-radius: 5px;
  }
`;



const Text = styled.p`
  padding: 0;
  margin: 0;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  text-align: left;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #000000;
`;


const Addy = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin: 10px 0;
  @media (max-width: 600px) {
    margin: 10px 0;
  }
`;

const Icon = styled.p`
  z-index: 2;
  width: 10px;
  height: 10px;
  @media (max-width: 600px) {
    width: 20px;
    height: 20px;
  }
`;

const Place = styled.div`
  padding: 0;
  margin: 0;
  width: 80%;
`;

const Time = styled.p`
  padding: 0;
  margin: 0;
  color: #545454;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
`;
