import styled from "styled-components";
import api from "../services/api";
import Bugsnag from "@bugsnag/js";
import BasicModal from "./Modal";
import Feedback from "./Feedback";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import Spinner from "./Spinner";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const CheckoutContent = () => {
  const navigate = useNavigate();
  const [openOrder, setOpenOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [failedOrders, setFailedOrders] = useState([]);
  const apiRequest = api();
  const payload = localStorage.getItem("NON-SUB-DELIVERY") as string;
  const order: any = JSON.parse(payload);
  const [feedbackModal, setFeedbackModal] = useState({
    open: false,
    type: "",
    message: "",
  });

  const formData = useSelector((state: RootState) => state.formData.formData);

  const handleSubmit = async () => {
    setLoading(true);
    await apiRequest
      .placeOrder(order)
      .then((response) => {
        localStorage.removeItem("NON-SUB-DELIVERY")
        if (response?.successfulOrderCount > 0) {
          setOpenOrder(true);
        }
        if (response?.failedOrderCount > 0) {
          setFailedOrders(response?.failedOrders);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setFeedbackModal((prev) => {
          return {
            ...prev,
            open: true,
            type: "error",
            message:
              err?.response?.data?.error?.data?.failedOrders[0]?.error ||
              err?.response?.data?.error?.message ||
              err?.response?.data?.error ||
              "Location is outside our jurisdiction for pickup/delivery",
          };
        });
        Bugsnag.notify(err);
      });
  };

  return (
    <Wrapper>

      <Content>
        <Goback onClick={() => window.history.back()}><BsArrowLeft fontSize={"24px"} /> Go Back</Goback>
   
        <Orderdetails>
          <div
            className="orderidbtn"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h1>Order Summary</h1>
            <button
              style={{
                backgroundColor: "#F66D00",
                borderColor: "#F66D00",
                color: "#fff",
                border: "none",
              }}
              onClick={handleSubmit}
            >
              {loading ? <Spinner /> : "Complete Order"}
            </button>
          </div>
          <div className="pickupdetails pickup ">
            <h4>Pickup Details</h4>
            <div className="grid">
              <div>
                <p>
                  <strong>Name</strong>
                </p>
                <p>
                  {order?.pickUpDetails
                    ? order?.pickUpDetails[0]?.senderName
                    : "Not available"}
                </p>
              </div>
              <div>
                <p>
                  <strong>Phone Number </strong>
                </p>
                <p>
                  {order?.pickUpDetails
                    ? `+234${order?.pickUpDetails[0]?.phoneNumber}`
                    : "Not available"}
                </p>
              </div>
              <div>
                <p>
                  <strong>Address </strong>
                </p>
                <p>
                  {order?.pickUpDetails
                    ? order?.pickUpDetails[0]?.fullAddress
                    : "Not available"}
                </p>
              </div>
              <div>
                <p>
                  <strong>State </strong>
                </p>
                <p>
                  {order?.pickUpDetails
                    ? order?.pickUpDetails[0]?.state
                    : "Not available"}
                </p>
              </div>
            </div>
          </div>
          {order?.deliveryDetails.map((items: any, index: number) => {
            return (
              <div key={index} className="deliverydetails delivery">
                <h4>Delivery Details {index + 1}</h4>
                <div className="grid">
                  <div>
                    <p>
                      <strong>Name </strong>
                    </p>
                    <p>{items ? items?.recipientName : "Not available"}</p>
                  </div>
                  <div>
                    <p>
                      <strong>Phone Number </strong>
                    </p>
                    <p>
                      {items ? `+234${items?.phoneNumber}` : "Not available"}
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>Address </strong>
                    </p>
                    <p>{items ? items?.fullAddress : "Not available"}</p>
                  </div>
                  <div>
                    <p>
                      <strong>State </strong>
                    </p>
                    <p>{items?.state || items?.cityName || "Not available"}</p>
                  </div>
                  <div>
                    <p>
                      <strong>Item Description </strong>
                    </p>
                    <p>
                      {items?.itemDescription ||
                        order?.description ||
                        "Not available"}
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>Extra Details </strong>
                    </p>
                    <p>{items?.additionalDetails || "Not available"}</p>
                  </div>
                  <div>
                    <p>
                      <strong>Weight </strong>
                    </p>
                    <p>{`${items?.itemWeight} kg` || "Not available"}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </Orderdetails>
      </Content>
      <BasicModal
        width={500}
        height={300}
        open={openOrder}
        close={() => setOpenOrder(false)}
        content={
          <OrderPlaced>
            <div>
              <h1>Order Successfully placed!</h1>
            </div>
            <div>
              <p className="order-pick">Your order will be picked up soon.</p>
            </div>
            {failedOrders.length > 0 && (
              <div style={{ padding: '20px'}}>
                <h2 style={{ textAlign:'center'}}>Some orders failed</h2>
                {failedOrders.map((orders: any) => (
                  <div style={{marginBottom: '10px'}}>
                    <p>Name: {orders?.data?.recipientName}</p>
                    <p>Address: {orders?.data?.fullAddress}</p>
                    <p>Error: {orders?.error}</p>
                  </div>
                ))}
              </div>
            )}
            <CashButton
              onClick={() => {
                localStorage.removeItem("NON-SUB-DELIVERY");
                navigate("/home");
              }}
            >
              Return to Home
            </CashButton>
          </OrderPlaced>
        }
      />
      <Feedback
        message={feedbackModal?.message}
        feedback={feedbackModal?.type}
        open={feedbackModal?.open}
        close={() =>
          setFeedbackModal((prev) => {
            return { ...prev, open: false };
          })
        }
      />
    </Wrapper>
  );
};

export default CheckoutContent;

const Wrapper = styled.main`
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: space-between;
  margin: 0%;
  background-color: #fafafa;

  .sidebar {
    width: 10%;
  }

  @media (max-width: 1024px) {
    .sidebar {
      width: 20%;
      background-color: #ffffff;
    }
  }
`;

const Goback = styled.p`
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  font-weight: 600;
  color: #F66D00;
`

const Content = styled.section`
  width: 90%;
  margin-left: 5%;
  margin-top: 100px;
  @media (max-width: 1024px) {
    width: 80%;
    margin: 0%;
  }
  @media (max-width: 600px) {
    width: 100%;
    margin: 0%;
  }
  .international {
    display: grid;
    grid-template-columns: 1fr 2fr;
    row-gap: 20px;
    column-gap: 80px;
    @media (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 80px;

    > div {
      text-align: left;
    }
    @media (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  h4 {
    margin: 2em auto;
  }
  .orderidbtn {
    margin: auto;
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
  .orderidbtn button {
    width: 160px;
    height: 50px;
    margin-left: 1em;
    font-size: 12px;
    font-weight: 800;
    padding: 0%;
    border-radius: 0.5em;
  }
  .orderidbtn button:hover {
    font-size: 14px;
  }

  .orderidbtn button:disabled {
    opacity: 0.4;
  }
`;

const Orderdetails = styled.section`
  width: 100%;
  background-color: #ffffff;
  padding: 30px;

  .pickup {
    background-color: #ffffff;
    width: 90%;
    margin: 1em auto;
    border-radius: 0.5em;
    h4 {
      text-align: left;
      color: #333333;
    }
  }
  .delivery {
    background-color: #ffffff;
    width: 90%;
    margin: 1em auto;
    border-radius: 0.5em;
    h4 {
      text-align: left;
      color: #333333;
    }
  }

  > h2 {
    /* text-transform: uppercase; */
  }
  > div {
    /* display: flex; */
    > div {
      /* width: 50%; */
      > p {
        margin-top: 10px;
        > strong {
          font-family: "Red Hat Display";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;

          /* Gray 2 */

          color: #4f4f4f;
        }
      }
    }
  }
`;

const OrderPlaced = styled.section`
  display: flex;
  background: #000000;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;
  margin: 0;
  gap: 15px;
  color: #fff;

  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 21px;
    text-align: center;
    color: #fff;
  }

  .estimate {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #333333;
    > span {
      font-weight: 500;
    }
  }
  .order-pick {
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
    /* identical to box height */

    text-align: left;

    /* Gray 1 */

    color: #0aedb4;
  }
  .kindly-note {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    color: #eb5757;
    width: 100%;
  }
`;

const CashButton = styled.button`
  margin: 0 auto;
  text-align: center;
  display: block;
  /* width: 450px; */
  width: 60%;
  height: 45px;
  margin-bottom: 40px;
  background-color: #ffffff;
  color: #000;
  border-radius: 6px;
  border: none;
  font-size: 18px;
  font-weight: 500;
  @media (max-width: 425px) {
    width: 90%;
  }
  &:disabled {
    background-color: red;
  }
`;
